import {DatePipe, NgClass} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CardModule} from 'primeng/card';
import {GoogleIcon} from 'src/app/enums/core/google-icon.enum';
import {Severity} from 'src/app/enums/core/severity.enum';
import {Size} from 'src/app/enums/core/size.enum';
import {DATE_TIME_FORMAT} from 'src/app/helpers/core/site-config.helper';
import {PRIORITY_SEVERITY_MAP} from 'src/app/helpers/radiology-tasks.helper';
import {Pageable} from 'src/app/interfaces/backend/core/pageable.interface';
import {NotificationDto} from 'src/app/interfaces/backend/dto/notification-dto.interface';
import {ButtonsModule} from 'src/app/modules/buttons/buttons.module';
import {TextShortenerPipe} from 'src/app/pipes/text-shortener.pipe';

const RAD_MODULES = [
    ButtonsModule
];

const RAD_STANDALONE_PIPES = [
    TextShortenerPipe
];

const PRIME_NG_MODULES = [
    CardModule
];

@Component({
    selector: 'app-notification',
    standalone: true,
    imports: [
        DatePipe,
        TranslateModule,
        NgClass,
        ...RAD_MODULES,
        ...RAD_STANDALONE_PIPES,
        ...PRIME_NG_MODULES
    ],
    templateUrl: './notification.component.html'
})
export class NotificationComponent {
    @Input() public notification?: NotificationDto;
    @Input() public clickable?: boolean;
    @Input() public dialogMode?: boolean;
    @Input() public hideButtons?: boolean;

    @Output() public markAsRead: EventEmitter<NotificationDto> = new EventEmitter<NotificationDto>();
    @Output() public goToSubject: EventEmitter<NotificationDto> = new EventEmitter<NotificationDto>();

    protected perPage: number = 10;
    protected pageable: Pageable = {page: 0, size: this.perPage};

    protected readonly PRIORITY_SEVERITY_MAP = PRIORITY_SEVERITY_MAP;
    protected readonly DATE_TIME_FORMAT = DATE_TIME_FORMAT;
    protected readonly Severity = Severity;
    protected readonly GoogleIcon = GoogleIcon;
    protected readonly Size = Size;

    protected onMarkAsRead($event: MouseEvent, entry: NotificationDto) {
        $event.stopPropagation();
        this.markAsRead.emit(entry);
    }

    protected onGoToSubject(entry: NotificationDto) {
        if (!this.clickable) return;

        this.goToSubject.emit(entry);
    }
}