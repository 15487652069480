import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BadgesFacade} from 'src/app/facades/badges.facade';
import {NotificationsFacade} from 'src/app/facades/notifications.facade';
import {ProfileFacade} from 'src/app/facades/profile.facade';
import {BE_DATA_FETCH_INTERVAL_TIME} from 'src/app/helpers/core/site-config.helper';
import {setInterval} from 'worker-timers';

@Injectable({
    providedIn: 'root'
})
export class SchedulerService {
    private schedulerInterval: number | null = null;
    private userLogged$: Observable<boolean>;

    constructor(
        private profileFacade: ProfileFacade,
        private notificationsFacade: NotificationsFacade,
        private badgesFacade: BadgesFacade
    ) {
        this.userLogged$ = this.profileFacade.userLogged$;
        this.initiateWatchers();
    }

    private initiateWatchers() {
        this.watchForUserLogged();
    }

    private watchForUserLogged() {
        return this.userLogged$.subscribe((value) => {
            if (!value && !this.schedulerInterval) return;

            this.schedulerLogic();
            this.schedulerInterval = setInterval(() => this.schedulerLogic(), BE_DATA_FETCH_INTERVAL_TIME);
        });
    }

    private schedulerLogic() {
        if (!this.profileFacade.userProfileSignal()) return;

        this.notificationsFacade.loadDataPeek();
        this.notificationsFacade.loadCountOfUnreadWebNotifications();
        this.notificationsFacade.loadDataImportant();
        this.badgesFacade.loadRadiologyTaskCountPerStatus();
    }
}