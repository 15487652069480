import {ComponentName} from 'src/app/enums/core/component-name.enum';
import {ComponentLoader} from 'src/app/interfaces/core/component-loader.interface';

export const SITE_COMPONENT_LOADER: ComponentLoader = {
    [ComponentName.AI]: () => import(`src/app/components/views/ai/ai.component`).then(c => c.AIComponent),
    [ComponentName.AIEdit]: () => import(`src/app/components/views/ai-edit/ai-edit.component`).then(c => c.AIEditComponent),
    [ComponentName.DoctorAssignedResidents]: () => import(`src/app/components/views/doctor-assigned-residents/doctor-assigned-residents.component`).then(c => c.DoctorAssignedResidentsComponent),
    [ComponentName.DoctorAssignedResidentCreate]: () => import(`src/app/components/views/doctor-assigned-resident-create/doctor-assigned-resident-create.component`).then(c => c.DoctorAssignedResidentCreateComponent),
    [ComponentName.DutiesCalendar]: () => import(`src/app/components/views/duties-calendar/duties-calendar.component`).then(c => c.DutiesCalendarComponent),
    [ComponentName.Invoices]: () => import(`src/app/components/views/invoices/invoices.component`).then(c => c.InvoicesComponent),
    [ComponentName.Login]: () => import(`src/app/components/views/login/login.component`).then(c => c.LoginComponent),
    [ComponentName.Modalities]: () => import(`src/app/components/views/modalities/modalities.component`).then(c => c.ModalitiesComponent),
    [ComponentName.PasswordRecovery]: () => import(`src/app/components/views/password-recovery/password-recovery.component`).then(c => c.PasswordRecoveryComponent),
    [ComponentName.PasswordReset]: () => import(`src/app/components/views/password-reset/password-reset.component`).then(c => c.PasswordResetComponent),
    [ComponentName.Patient]: () => import(`src/app/components/views/patient/patient.component`).then(c => c.PatientComponent),
    [ComponentName.PatientCreate]: () => import(`src/app/components/views/patient-create/patient-create.component`).then(c => c.PatientCreateComponent),
    [ComponentName.PatientEdit]: () => import(`src/app/components/views/patient-edit/patient-edit.component`).then(c => c.PatientEditComponent),
    [ComponentName.Patients]: () => import(`src/app/components/views/patients/patients.component`).then(c => c.PatientsComponent),
    [ComponentName.ProcedureCreate]: () => import(`src/app/components/views/procedure-create/procedure-create.component`).then(c => c.ProcedureCreateComponent),
    [ComponentName.ProcedureEdit]: () => import(`src/app/components/views/procedure-edit/procedure-edit.component`).then(c => c.ProcedureEditComponent),
    [ComponentName.Procedures]: () => import(`src/app/components/views/procedures/procedures.component`).then(c => c.ProceduresComponent),
    [ComponentName.ProceduresRates]: () => import(`src/app/components/views/procedures-rates/procedures-rates.component`).then(c => c.ProceduresRatesComponent),
    [ComponentName.ProfileData]: () => import(`src/app/components/views/profile-data/profile-data.component`).then(c => c.ProfileDataComponent),
    [ComponentName.Radiologist]: () => import(`src/app/components/views/radiologist/radiologist.component`).then(c => c.RadiologistComponent),
    [ComponentName.RadiologistProceduresRatesUnitCreate]: () => import(`src/app/components/views/radiologist-procedures-rates-unit-create/radiologist-procedures-rates-unit-create.component`).then(c => c.RadiologistProceduresRatesUnitCreateComponent),
    [ComponentName.RadiologistProceduresRatesUnitProceduresRates]: () => import(`src/app/components/views/radiologist-procedures-rates-unit-procedures-rates/radiologist-procedures-rates-unit-procedures-rates.component`).then(c => c.RadiologistProceduresRatesUnitProceduresRatesComponent),
    [ComponentName.RadiologistProceduresRatesUnitProceduresRatesEdit]: () => import(`src/app/components/views/radiologist-procedures-rates-unit-procedures-rates-edit/radiologist-procedures-rates-unit-procedures-rates-edit.component`).then(c => c.RadiologistProceduresRatesUnitProceduresRatesEditComponent),
    [ComponentName.Radiologists]: () => import(`src/app/components/views/radiologists/radiologists.component`).then(c => c.RadiologistsComponent),
    [ComponentName.RadiologyTask]: () => import(`src/app/components/views/radiology-task/radiology-task.component`).then(c => c.RadiologyTaskComponent),
    [ComponentName.RadiologyTaskCategories]: () => import(`src/app/components/views/radiology-task-categories/radiology-task-categories.component`).then(c => c.RadiologyTaskCategoriesComponent),
    [ComponentName.RadiologyTaskCategoryCreate]: () => import(`src/app/components/views/radiology-task-category-create/radiology-task-category-create.component`).then(c => c.RadiologyTaskCategoryCreateComponent),
    [ComponentName.RadiologyTaskCategoryEdit]: () => import(`src/app/components/views/radiology-task-category-edit/radiology-task-category-edit.component`).then(c => c.RadiologyTaskCategoryEditComponent),
    [ComponentName.RadiologyTaskCreate]: () => import(`src/app/components/views/radiology-task-create/radiology-task-create.component`).then(c => c.RadiologyTaskCreateComponent),
    [ComponentName.RadiologyTaskDescriptionTemplate]: () => import(`src/app/components/views/radiology-task-description-template/radiology-task-description-template.component`).then(c => c.RadiologyTaskDescriptionTemplateComponent),
    [ComponentName.RadiologyTaskDescriptionTemplateCategoryCreate]: () => import(`src/app/components/views/radiology-task-description-template-category-create/radiology-task-description-template-category-create.component`).then(c => c.RadiologyTaskDescriptionTemplateCategoryCreateComponent),
    [ComponentName.RadiologyTaskDescriptionTemplateCategoryEdit]: () => import(`src/app/components/views/radiology-task-description-template-category-edit/radiology-task-description-template-category-edit.component`).then(c => c.RadiologyTaskDescriptionTemplateCategoryEditComponent),
    [ComponentName.RadiologyTaskDescriptionTemplateCreate]: () => import(`src/app/components/views/radiology-task-description-template-create/radiology-task-description-template-create.component`).then(c => c.RadiologyTaskDescriptionTemplateCreateComponent),
    [ComponentName.RadiologyTaskDescriptionTemplateEdit]: () => import(`src/app/components/views/radiology-task-description-template-edit/radiology-task-description-template-edit.component`).then(c => c.RadiologyTaskDescriptionTemplateEditComponent),
    [ComponentName.RadiologyTaskDescriptionTemplatesCategories]: () => import(`src/app/components/views/radiology-task-description-templates-categories/radiology-task-description-templates-categories.component`).then(c => c.RadiologyTaskDescriptionTemplatesCategoriesComponent),
    [ComponentName.RadiologyTaskRedirect]: () => import(`src/app/components/views/radiology-task-redirect/radiology-task-redirect.component`).then(c => c.RadiologyTaskRedirectComponent),
    [ComponentName.RadiologyTasksAssigned]: () => import(`src/app/components/views/radiology-tasks-assigned/radiology-tasks-assigned.component`).then(c => c.RadiologyTasksAssignedComponent),
    [ComponentName.RadiologyTasksDescribed]: () => import(`src/app/components/views/radiology-tasks-described/radiology-tasks-described.component`).then(c => c.RadiologyTasksDescribedComponent),
    [ComponentName.RadiologyTasksFinished]: () => import(`src/app/components/views/radiology-tasks-finished/radiology-tasks-finished.component`).then(c => c.RadiologyTasksFinishedComponent),
    [ComponentName.RadiologyTasksForVerification]: () => import(`src/app/components/views/radiology-tasks-for-verification/radiology-tasks-for-verification.component`).then(c => c.RadiologyTasksForVerificationComponent),
    [ComponentName.RadiologyTasksInVerification]: () => import(`src/app/components/views/radiology-tasks-in-verification/radiology-tasks-in-verification.component`).then(c => c.RadiologyTasksInVerificationComponent),
    [ComponentName.RadiologyTasksNew]: () => import(`src/app/components/views/radiology-tasks-new/radiology-tasks-new.component`).then(c => c.RadiologyTasksNewComponent),
    [ComponentName.RadiologyTasksNotAssigned]: () => import(`src/app/components/views/radiology-tasks-not-assigned/radiology-tasks-not-assigned.component`).then(c => c.RadiologyTasksNotAssignedComponent),
    [ComponentName.RadiologyTasksRejected]: () => import(`src/app/components/views/radiology-tasks-rejected/radiology-tasks-rejected.component`).then(c => c.RadiologyTasksRejectedComponent),
    [ComponentName.RadiologyTasksSigned]: () => import(`src/app/components/views/radiology-tasks-signed/radiology-tasks-signed.component`).then(c => c.RadiologyTasksSignedComponent),
    [ComponentName.RadiologyTasksTemplates]: () => import(`src/app/components/views/radiology-task-descriptions-templates/radiology-task-descriptions-templates.component`).then(c => c.RadiologyTaskDescriptionsTemplatesComponent),
    [ComponentName.RadiologyTasksUnderDiscussion]: () => import(`src/app/components/views/radiology-tasks-under-discussion/radiology-tasks-under-discussion.component`).then(c => c.RadiologyTasksUnderDiscussionComponent),
    [ComponentName.Reports]: () => import(`src/app/components/views/reports/reports.component`).then(c => c.ReportsComponent),
    [ComponentName.Sessions]: () => import(`src/app/components/views/sessions/sessions.component`).then(c => c.SessionsComponent),
    [ComponentName.SettingsPersonal]: () => import(`src/app/components/views/settings-personal/settings-personal.component`).then(c => c.SettingsPersonalComponent),
    [ComponentName.SettlementCreate]: () => import(`src/app/components/views/settlement-create/settlement-create.component`).then(c => c.SettlementCreateComponent),
    [ComponentName.Settlements]: () => import(`src/app/components/views/settlements/settlements.component`).then(c => c.SettlementsComponent),
    [ComponentName.Tests]: () => import(`src/app/components/views/tests/tests.component`).then(c => c.TestsComponent),
    [ComponentName.Unit]: () => import(`src/app/components/views/unit/unit.component`).then(c => c.UnitComponent),
    [ComponentName.UnitCreate]: () => import(`src/app/components/views/unit-create/unit-create.component`).then(c => c.UnitCreateComponent),
    [ComponentName.UnitEdit]: () => import(`src/app/components/views/unit-edit/unit-edit.component`).then(c => c.UnitEditComponent),
    [ComponentName.Units]: () => import(`src/app/components/views/units/units.component`).then(c => c.UnitsComponent),
    [ComponentName.User]: () => import(`src/app/components/views/user/user.component`).then(c => c.UserComponent),
    [ComponentName.UserCreate]: () => import(`src/app/components/views/user-create/user-create.component`).then(c => c.UserCreateComponent),
    [ComponentName.UserEdit]: () => import(`src/app/components/views/user-edit/user-edit.component`).then(c => c.UserEditComponent),
    [ComponentName.Users]: () => import(`src/app/components/views/users/users.component`).then(c => c.UsersComponent)
};