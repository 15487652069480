import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Controller} from 'src/app/enums/backend/core/controller.enum';
import {EndpointPathParam} from 'src/app/enums/backend/core/endpoint-path-param.enum';
import {
    RadiologyNotificationControllerEndpoint
} from 'src/app/enums/backend/core/endpoints/radiology-notification-controller-endpoint.enum';
import {Page} from 'src/app/interfaces/backend/core/page.interface';
import {Pageable} from 'src/app/interfaces/backend/core/pageable.interface';
import {NotificationDto} from 'src/app/interfaces/backend/dto/notification-dto.interface';
import {Payload} from 'src/app/interfaces/core/payload.interface';
import {EndpointsService} from 'src/app/services/core/endpoints.service';

@Injectable({providedIn: 'root'})
export class RadiologyNotificationControllerService {
    private controller: Controller = Controller.RADIOLOGY_NOTIFICATION;

    public constructor(
        private endpointsService: EndpointsService
    ) {
    }

    public getWebNotifications(pageable: Pageable): Observable<Page<NotificationDto>> {
        const payload: Payload = {queryParams: pageable};
        return this.endpointsService.send(this.controller, RadiologyNotificationControllerEndpoint.GET_WEB_NOTIFICATIONS, payload);
    }

    public getImportantWebNotifications(): Observable<NotificationDto[]> {
        return this.endpointsService.send(this.controller, RadiologyNotificationControllerEndpoint.GET_IMPORTANT_WEB_NOTIFICATIONS);
    }

    public markNotificationAsRead(notificationId: number) {
        const payload: Payload = {pathParams: {[EndpointPathParam.ID]: notificationId}};
        return this.endpointsService.send(this.controller, RadiologyNotificationControllerEndpoint.MARK_NOTIFICATION_AS_READ, payload);
    }

    public markAllNotificationsAsRead() {
        return this.endpointsService.send(this.controller, RadiologyNotificationControllerEndpoint.MARK_ALL_NOTIFICATIONS_AS_READ);
    }

    public getCountOfUnreadWebNotifications(): Observable<number> {
        return this.endpointsService.send(this.controller, RadiologyNotificationControllerEndpoint.GET_COUNT_OF_UNREAD_WEB_NOTIFICATIONS);
    }
}