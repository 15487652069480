import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Params, RouterStateSnapshot} from '@angular/router';
import {catchError, defaultIfEmpty, forkJoin, map, Observable, of, pipe, switchMap, throwError} from 'rxjs';
import {Role} from 'src/app/enums/backend/role.enum';
import {LoginState} from 'src/app/enums/core/login-state.enum';
import {RouteDataType} from 'src/app/enums/core/route-data.enum';
import {SiteNodeConditionType} from 'src/app/enums/core/site-node-condition-type.enum';
import {SiteNodeId} from 'src/app/enums/core/site-node-id.enum';
import {SiteNodeSpecialGuard} from 'src/app/enums/core/site-node-special-guard.enum';
import {SiteRoute} from 'src/app/enums/core/site-route.enum';
import {AIFacade} from 'src/app/facades/ai.facade';
import {PatientsFacade} from 'src/app/facades/patients.facade';
import {ProceduresFacade} from 'src/app/facades/procedures.facade';
import {ProfileFacade} from 'src/app/facades/profile.facade';
import {RadiologyTaskCategoriesFacade} from 'src/app/facades/radiology-task-categories.facade';
import {
    RadiologyTaskDescriptionTemplatesCategoriesFacade
} from 'src/app/facades/radiology-task-description-templates-categories.facade';
import {RadiologyTaskDescriptionsTemplatesFacade} from 'src/app/facades/radiology-task-descriptions-templates.facade';
import {RadiologyTasksFacade} from 'src/app/facades/radiology-tasks.facade';
import {UnitsFacade} from 'src/app/facades/units.facade';
import {UsersFacade} from 'src/app/facades/users.facade';
import {REQUESTS_HASH, updateRequestsHash} from 'src/app/helpers/core/misc.helper';
import {HOME_PAGE, SITE_ROUTING} from 'src/app/helpers/core/site-config.helper';
import {isAccessAllowedBySiteNodeConditions} from 'src/app/helpers/core/site-tree-logic.helper';
import {UserDto} from 'src/app/interfaces/backend/dto/user-dto.interface';
import {RouteSubjects} from 'src/app/interfaces/core/route-subjects.type';
import {SiteNodeCondition} from 'src/app/interfaces/core/site-node-condition.interface';
import {SiteNodeGuard} from 'src/app/interfaces/core/site-node-guard.type';
import {CoreService} from 'src/app/services/core/core.service';
import {ToastService} from 'src/app/services/core/toast.service';
import {includes} from 'src/app/utilities/misc.util';
import {arrayToObject} from 'src/app/utilities/object.util';
import {getToken} from 'src/app/utilities/token.util';

export const coreRouteActivator: CanActivateFn = (activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot) => {
    updateRequestsHash();
    const coreService = inject(CoreService);
    const profileFacade = inject(ProfileFacade);
    const aiFacade = inject(AIFacade);
    const toastService = inject(ToastService);
    const tokenExists = Boolean(getToken());
    const secured = activatedRouteSnapshot.data[RouteDataType.SECURED];
    const path = activatedRouteSnapshot.url.map(entry => entry.path).join('/');
    const subjectsRequests = getSubjects(activatedRouteSnapshot.params);

    const requests = {
        requireAiTask: secured ? aiFacade.getRequireAiTask() : of(false),
        subjects: forkJoin(subjectsRequests).pipe(defaultIfEmpty({}))
    };

    return (secured || tokenExists ? profileFacade.getUserProfile() : of(profileFacade.userProfileSignal())).pipe(
        switchMap((profile) => {
            profileFacade.setUserProfile(profile);
            return forkJoin({
                ...requests,
                profile: of(profile),
                activatedRouteSnapshot: of(activatedRouteSnapshot)
            })
        }),
        map(({requireAiTask, subjects, profile}) => {
            let result = true;
            aiFacade.requireAiTask = requireAiTask;
            if (tokenExists && [
                SITE_ROUTING.SITE_NODE_IDS_PATHS[HOME_PAGE],
                SITE_ROUTING.SITE_NODE_IDS_PATHS[SiteNodeId.AAX]
            ].includes(routerStateSnapshot.url.slice(1, routerStateSnapshot.url.length))) {
                profileFacade.proceedAfterSuccessfulLogin();
                return true;
            } else {
                const siteNodeConditionsVerificationResult = verifySiteNodeConditions(activatedRouteSnapshot, profile);
                const guardsVerificationResult = verifyGuards(activatedRouteSnapshot, subjects);
                const specialGuardVerificationResult = verifySpecialGuard(activatedRouteSnapshot, profile, subjects);
                if (!siteNodeConditionsVerificationResult || !guardsVerificationResult || !specialGuardVerificationResult) {
                    coreService.onError(path);
                    result = false;
                }
            }

            if (!result) toastService.displayError();
            if (result) coreService.updateRouteData(activatedRouteSnapshot, subjects);
            return result;
        }),
        catchError((err) => {
            coreService.onError(path);
            return throwError(() => err);
        })
    );
};

const getSubjects = (params: Params) => {
    const requestsArray = Object.keys(params).map((entry) => {
        const paramKey = ':' + entry;
        const param = params[entry];
        let request: {[key: string]: Observable<any>} = {};

        switch (paramKey) {
            case SiteRoute.P_RADIOLOGY_TASK_ID:
                const radiologyTasksFacade = inject(RadiologyTasksFacade);
                request[paramKey] = radiologyTasksFacade.handleSubject(param, REQUESTS_HASH);
                break;

            case SiteRoute.P_UNIT_ID:
                const unitsFacade = inject(UnitsFacade);
                request[paramKey] = unitsFacade.handleSubject(param, REQUESTS_HASH);
                break;

            case SiteRoute.P_USER_ID:
                const usersFacade = inject(UsersFacade);
                request[paramKey] = usersFacade.handleSubject(param, REQUESTS_HASH);
                break;

            case SiteRoute.P_RAD_TEMPLATE_ID:
                const radiologyTaskDescriptionsTemplatesFacade = inject(RadiologyTaskDescriptionsTemplatesFacade);
                request[paramKey] = radiologyTaskDescriptionsTemplatesFacade.handleSubject(param, REQUESTS_HASH);
                break;

            case SiteRoute.P_PATIENT_ID:
                const patientsFacade = inject(PatientsFacade);
                request[paramKey] = patientsFacade.handleSubject(param, REQUESTS_HASH);
                break;

            case SiteRoute.P_RADIOLOGY_TASK_CATEGORY_ID:
                const radiologyTaskCategoriesFacade = inject(RadiologyTaskCategoriesFacade);
                request[paramKey] = radiologyTaskCategoriesFacade.handleSubject(param, REQUESTS_HASH);
                break;

            case SiteRoute.P_RADIOLOGY_TASK_DESCRIPTION_TEMPLATE_CATEGORY_ID:
                const radiologyTaskDescriptionTemplatesCategoriesFacade = inject(RadiologyTaskDescriptionTemplatesCategoriesFacade);
                request[paramKey] = radiologyTaskDescriptionTemplatesCategoriesFacade.handleSubject(param, REQUESTS_HASH);
                break;

            case SiteRoute.P_AI_ID:
                const aiFacade = inject(AIFacade);
                request[paramKey] = aiFacade.handleSubject(param, REQUESTS_HASH);
                break;

            case SiteRoute.P_PROCEDURE_ID:
                const proceduresFacade = inject(ProceduresFacade);
                request[paramKey] = proceduresFacade.handleSubject(param, REQUESTS_HASH);
                break;
        }

        return request;
    });

    const requests: {[key: string]: Observable<any>} = arrayToObject(requestsArray);
    return requests;
};

const verifySiteNodeConditions = (activatedRouteSnapshot: ActivatedRouteSnapshot, profile: UserDto | null) => {
    const siteNodeConditions: SiteNodeCondition[] = activatedRouteSnapshot.data[RouteDataType.SITE_NODE_CONDITIONS];
    const values = {
        [SiteNodeConditionType.LOGIN_STATE]: profile ? LoginState.LOGGED_IN : LoginState.NOT_LOGGED_IN,
        [SiteNodeConditionType.ROLE]: profile?.usType
    };
    return isAccessAllowedBySiteNodeConditions(values, siteNodeConditions);
};

const verifyGuards = (activatedRouteSnapshot: ActivatedRouteSnapshot, subjects: RouteSubjects) => {
    const guard = activatedRouteSnapshot.data[RouteDataType.GUARD] as SiteNodeGuard | undefined;
    if (!guard) return true;

    return guard(subjects);
};

const verifySpecialGuard = (activatedRouteSnapshot: ActivatedRouteSnapshot, profile: UserDto | null, subjects: RouteSubjects) => {
    const specialGuard = activatedRouteSnapshot.data[RouteDataType.SPECIAL_GUARD] as SiteNodeSpecialGuard | undefined;
    if (specialGuard === SiteNodeSpecialGuard.IS_USER_ASSIGNED_TO_RADIOLOGY_TASK) {
        if (includes([Role.MANAGER, Role.INSTITUTION], profile?.usType)) return true;
        const userId = profile?.idUser;
        const radiologyTask = subjects[SiteRoute.P_RADIOLOGY_TASK_ID];
        return userId === radiologyTask?.userId;
    }

    return true;
};

// TODO jeśli BE zwróci brak i nie przekieruje, dodać weryfikację wyników w oparciu o klucze z paramsów