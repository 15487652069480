import {Component} from '@angular/core';
import {filter, forkJoin, switchMap, tap} from 'rxjs';
import {NotificationComponent} from 'src/app/components/sub-components/notification/notification.component';
import {DialogComponent} from 'src/app/components/utilities/dialog/dialog.component';
import {NotificationContext} from 'src/app/enums/backend/notification-context.enum';
import {Role} from 'src/app/enums/backend/role.enum';
import {NotificationsFacade} from 'src/app/facades/notifications.facade';
import {ProfileFacade} from 'src/app/facades/profile.facade';
import {GAP_Y} from 'src/app/helpers/core/site-config.helper';
import {NotificationDto} from 'src/app/interfaces/backend/dto/notification-dto.interface';
import {nonNullable} from 'src/app/utilities/object.util';

const RAD_STANDALONE_COMPONENTS = [
    NotificationComponent,
    DialogComponent
];

@Component({
    selector: 'app-notifications-important',
    standalone: true,
    imports: [
        ...RAD_STANDALONE_COMPONENTS
    ],
    templateUrl: './notifications-important.component.html'
})
export class NotificationsImportantComponent {
    protected readonly GAP_Y = GAP_Y;
    protected readonly NotificationContext = NotificationContext;
    protected readonly Role = Role;

    constructor(
        protected notificationsFacade: NotificationsFacade,
        protected profileFacade: ProfileFacade
    ) {
    }

    protected markAllAsRead(fn?: () => void) {
        if (!this.notificationsFacade.dataImportant.length) return;

        const requests = this.notificationsFacade.dataImportant.map(entry => {
            const {id} = entry;
            return id ? this.notificationsFacade.markNotificationAsReadLogic(id) : null;
        }).filter(nonNullable);

        forkJoin(requests).pipe(
            switchMap(() => this.notificationsFacade.loadDataImportantLogic()),
            tap(() => fn?.())
        ).subscribe();
    }

    protected onGoToSubject($event: NotificationDto) {
        const fn = () => this.notificationsFacade.goToSubject($event);
        this.markAllAsRead(fn);
    }
}