import {Injectable, Injector} from '@angular/core';
import {map, tap} from 'rxjs';
import {NotificationContext} from 'src/app/enums/backend/notification-context.enum';
import {QueryParam} from 'src/app/enums/core/query-param.enum';
import {SiteNodeId} from 'src/app/enums/core/site-node-id.enum';
import {SiteRoute} from 'src/app/enums/core/site-route.enum';
import {Sort} from 'src/app/enums/core/sort.enum';
import {RadiologyTaskView} from 'src/app/enums/views/radiology-task-view.enum';
import {RadiologyTasksFacade} from 'src/app/facades/radiology-tasks.facade';
import {RADIOLOGY_TASK_ON_ACTIONS_VIEW} from 'src/app/helpers/core/site-tree.helper';
import {STATUS_SITE_NODE_ACTION_MAP} from 'src/app/helpers/radiology-tasks.helper';
import {Page} from 'src/app/interfaces/backend/core/page.interface';
import {Pageable} from 'src/app/interfaces/backend/core/pageable.interface';
import {NotificationDto} from 'src/app/interfaces/backend/dto/notification-dto.interface';
import {NavigationParams} from 'src/app/interfaces/core/navigation-params.interface';
import {NavigationQueryParams} from 'src/app/interfaces/core/navigation-query-params.interface';
import {TypedObject} from 'src/app/interfaces/core/typed-object.interface';
import {
    RadiologyNotificationControllerService
} from 'src/app/services/backend/radiology-notification-controller.service';
import {RoutingService} from 'src/app/services/core/routing.service';
import {includes} from 'src/app/utilities/misc.util';
import {displayError, displaySuccess} from 'src/app/utilities/toast.util';

@Injectable({providedIn: 'root'})
export class NotificationsFacade {
    public displayNotificationsSidebar: boolean = false;
    public displayNotificationsDialog: boolean = false;
    public unreadNotificationsCount: number = 0;

    public dataPage: Page<NotificationDto> | null = null;
    public dataPagePeek: Page<NotificationDto> | null = null;
    public dataImportant: NotificationDto[] = [];

    constructor(
        private radiologyTasksFacade: RadiologyTasksFacade,
        private radiologyNotificationControllerService: RadiologyNotificationControllerService,
        private routingService: RoutingService,
        private injector: Injector
    ) {
    }

    public loadData(pageable: Pageable = {}) {
        this.radiologyNotificationControllerService.getWebNotifications({
            ...pageable,
            sort: [`notificationDate,${Sort.DESC}`]
        }).subscribe(data => this.dataPage = data);
    }

    public loadDataPeek() {
        this.radiologyNotificationControllerService.getWebNotifications({
            size: 10,
            page: 0,
            sort: [`notificationDate,${Sort.DESC}`]
        }).subscribe(data => this.dataPagePeek = data);
    }

    public loadDataImportant() {
        this.loadDataImportantLogic().subscribe();
    }

    public loadDataImportantLogic() {
        return this.radiologyNotificationControllerService.getImportantWebNotifications().pipe(
            map(data => this.dataImportant = data)
        );
    }

    public markNotificationAsRead(notificationId: number, callback?: () => void) {
        this.markNotificationAsReadLogic(notificationId).subscribe(() => {
            displaySuccess(this.injector);
            callback?.();
        });
    }

    public markNotificationAsReadLogic(notificationId: number) {
        return this.radiologyNotificationControllerService.markNotificationAsRead(notificationId);
    }

    public markAllNotificationsAsRead(callback?: () => void) {
        this.radiologyNotificationControllerService.markAllNotificationsAsRead().subscribe(() => {
            displaySuccess(this.injector);
            callback?.();
        });
    }

    public loadCountOfUnreadWebNotifications() {
        this.radiologyNotificationControllerService.getCountOfUnreadWebNotifications()
            .subscribe(data => this.unreadNotificationsCount = data);
    }

    public goToSubject(notificationDto: NotificationDto) {
        const {radTaskId, eventId, context, newDateStart, newDateStop} = notificationDto;

        if (includes([NotificationContext.RadiologyTask, NotificationContext.RadiologyTaskNote], context)) {
            if (!radTaskId) {
                displayError(this.injector);
                return;
            }

            this.radiologyTasksFacade.getItem(radTaskId).pipe(
                tap((data) => {
                    const status = data.status;
                    if (!status) {
                        displayError(this.injector);
                        return;
                    }

                    this.displayNotificationsSidebar = false;
                    this.displayNotificationsDialog = false;

                    const siteNodeId = (RADIOLOGY_TASK_ON_ACTIONS_VIEW as TypedObject<SiteNodeId>)[STATUS_SITE_NODE_ACTION_MAP[status]];
                    const params: NavigationParams = {[SiteRoute.P_RADIOLOGY_TASK_ID]: radTaskId};
                    const queryParams: NavigationQueryParams | undefined = context === NotificationContext.RadiologyTaskNote ?
                        {[QueryParam.TAB]: RadiologyTaskView.NOTES} :
                        undefined;

                    this.routingService.navigateBySiteNodeId(siteNodeId, {params, queryParams});
                })
            ).subscribe();
        }

        if (context === NotificationContext.RadiologyUserCalendar) {
            if (!eventId) {
                displayError(this.injector);
                return;
            }

            this.displayNotificationsSidebar = false;
            this.displayNotificationsDialog = false;
            const queryParams: NavigationQueryParams = {
                [QueryParam.EVENT_ID]: eventId,
                [QueryParam.NEW_DATE_START]: newDateStart,
                [QueryParam.NEW_DATE_STOP]: newDateStop
            };

            this.routingService.navigateBySiteNodeId(SiteNodeId.ABS, {queryParams});
        }
    }
}