@let notifications = notificationsFacade.dataImportant;
@let role = profileFacade.userProfileSignal()?.usType;

<app-dialog header="notification"
            [visible]="notifications.length"
            (xClose)="markAllAsRead()">
    <div class="d-flex flex-column"
         [class]="GAP_Y">
        @for (entry of notifications; track entry.id) {
            <app-notification [notification]="entry"
                              [clickable]="entry.context !== NotificationContext.RadiologyUserCalendar || entry.context === NotificationContext.RadiologyUserCalendar && role === Role.MANAGER"
                              [dialogMode]="true"
                              [hideButtons]="true"
                              (goToSubject)="onGoToSubject($event)"/>
        }
    </div>
</app-dialog>